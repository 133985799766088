import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl, FormattedMessage, Link } from '@intractive/gatsby-plugin-react-intl'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import Helmet from 'react-helmet'
import Layout from '../Layout'
import BlogArticleList from '../../components/BlogArticleList'
import MotieTable from '../MotieTable'

const style = require('./index.styles.css')

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Person extends React.Component {
  render() {
    const artikelen = this.props.data.artikelen
    const persoon = this.props.data.contentfulPersoon
    const moties = this.props.data.moties
    const {
      name,
      bio,
      image,
      jobtitle,
      email,
      phone,
      twitter,
      linkedIn,
      portefeuille,
      blogartikel,
      motie__amendement_of_toezegging,
    } = persoon

    return (
      <Layout>
        <Helmet>
          <title>{name} · STIP</title>
        </Helmet>
        <div className="main-content row">
          <div className="large-2 hide-for-medium columns sidebar hide-for-small">
            <Link to='/mensen/'>&#8592; <FormattedMessage id="Mensen" /></Link>
          </div>

          <div className="large-10 columns persoon">
            <div className="row">
              <article className="large-9 medium-9 columns">
                <h1>{name}</h1>
                {bio && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: bio.childMarkdownRemark.html,
                    }}
                  />
                )}
              </article>

              <aside
                className="large-3 medium-3 columns align-center person person-sidebar"
                itemType="http://schema.org/Person"
              >
                {image ? (
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.title}
                    itemProp="image"
                    className={`person-image round-image fotopersoonlijkepagina`} />
                ) : (
                  <img
                    className="person-image round-image"
                    src="https://www.deviersprong.nl/wp-content/uploads/2017/11/img-person-placeholder.jpg"
                    alt="Placeholder"
                  />
                )}
                <div className="person-name" itemProp="name">
                  {name}
                </div>
                <div className="person-desctiption small-text">
                  {jobtitle && (
                    <span itemProp="jobTitle">
                      {jobtitle}
                      <br />
                    </span>
                  )}
                  {phone && (
                    <span>
                      <a
                        className={style.descriptionLink}
                        href={`tel:${phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        itemProp="telephone"
                      >
                        {phone}
                      </a>
                      <br />
                    </span>
                  )}
                  {email && (
                    <span>
                      <a
                        className={style.descriptionLink}
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        itemProp="email"
                      >
                        {email}
                      </a>
                      <br />
                    </span>
                  )}

                  <div className="socialicons">
                    {twitter && (
      
                        <a
                          href={`https://www.twitter.com/${twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ borderBottom: 'none' }}
                        >
                          <i
                            className="fab fa-twitter-square"
                            style={{ fontSize: 30 + 'px' }}
                          ></i>
                        </a>
                    )}

                    {linkedIn && (
                      <a
                          href={linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ borderBottom: 'none' }}
                        >
                          <i
                            className="fab fa-linkedin"
                            style={{ fontSize: 30 + 'px' }}
                          ></i>
                      </a>
                    )}
                  </div>

                  {portefeuille && (
                    <span>
                      <br/>
                      <strong><FormattedMessage id="Portefeuilles"/>:</strong>
                    </span>
                  )}
                  {portefeuille &&
                    portefeuille.map(function(porto, index) {
                      const portefeuille = porto
                      const url = `/portefeuilles/${portefeuille.slug}/`
                      return (
                        <Link key={index} to={url}>
                          <span itemProp="portefeuillenaam">
                            <br/>
                            {portefeuille.titel}
                          </span>
                        </Link>
                      )
                    })}
                </div>
              </aside>
            </div>

            {motie__amendement_of_toezegging &&
              <div className="personMoties">
                <h2>Moties</h2>
                <p>
                  <FormattedMessage id="Hieronder staat een overzicht van alle voorstellen die"/> {name} <FormattedMessage id="heeft ingediend of gekregen"/>.<span> </span>
                  <FormattedMessage id='Het is mogelijk om te sorteren en filters toe te passen. In de "Type" kolom kan je zien of het gaat om een motie (M), amendement (A), Toezegging (T), Initiatiefvoorstel (I) of Schriftelijke Vragen (SV).' /><span> </span>
                  <FormattedMessage id="Voor een overzicht van alle voorstellen van de fractie, zie"/> <Link to="/speerpunten/moties/"><FormattedMessage id="de motiepagina"/></Link>
                </p>
                <MotieTable tabeldata={moties.edges}></MotieTable>
              </div>
            }
            
            {blogartikel && 
              <div>
                <h2><FormattedMessage id="Geschreven artikelen"/></h2>
                <div className="smallerArticleList">
                  <BlogArticleList articles={artikelen} />
                </div>
              </div>
            }

          </div>
        </div>
      </Layout>
    );
  }
}

Person.propTypes = propTypes

export default injectIntl(Person)

export const pageQuery = graphql`
  query($slug: String!, $node_locale: String!) {
    contentfulPersoon(slug: { eq: $slug }, node_locale: {eq: $node_locale}) {
      name
      bio {
        childMarkdownRemark {
          html
        }
      }
      jobtitle
      email
      phone
      twitter
      linkedIn
      image {
        title
        gatsbyImageData(formats: WEBP, width: 500)
      }
      portefeuille {
        titel
        slug
      }
      blogartikel {
        id
      }
      motie__amendement_of_toezegging {
        id
      }
    }
    moties: allContentfulMotieAmendementOfToezegging(sort: {fields: datumIngediend, order: DESC}, filter: {fractielid: {elemMatch: {slug: {eq: $slug }}}, node_locale: {eq: $node_locale}}) {
      edges {
        node {
          datumIngediend
          type
          motietitel
          indieners
          fractielid {
            name
            slug
          }
          portefeuilles {
            titel
            slug
          }
          beschrijving {
            beschrijving
          }
          resultaat
          risLink
        }
      }
    }
    artikelen: allContentfulBlogartikel(
      sort: { fields: date, order: DESC }
      filter: { auteur: { slug: { eq: $slug } }, node_locale: {eq: $node_locale}}
    ) {
      edges {
        node {
          id
          migrationId
          slug
          date(formatString: "DD - MM - YYYY")
          title
          image {
            title
            gatsbyImageData(formats: WEBP, width: 450)
          }
        }
      }
    }
  }
`
